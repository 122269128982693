import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, inject, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { Router } from '@angular/router';
import { Korisnik } from '../../../../Komunalno/SaldaKonti/Utuzivanje/Start/korisnik';
import { KarticaComponent } from '../../../../Komunalno/SaldaKonti/Kartica/kartica.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PokretanjePodaci } from 'src/app/_models/Utuzivanje/pokretanjepodaci';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';
import { KorisnikSelectComponent } from '../../../../Komunalno/Korisnik/korisnik-select.component';
import { KorisnikInfoComponent } from 'src/app/Komunalno/Korisnik/korisnik-info.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerIntl } from '@angular/material/datepicker';


@Component({
  selector: 'start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']  
  , animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0'})),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],  
})
export class StartComponent implements OnInit {
  getservice: httpservice;
  displayedColumns: string[] = ['sifra', 'ime', 'iznos', 'tipduga', 'pokrenipostupak'];
  
  


  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    if (this.lista) {
      value._intl = new MatPaginatorIntlCro();
      this.lista.paginator = value;
      this.lista.sort = this.sort;
    }
  };
  @ViewChild(MatSort) sort: MatSort;
  selectedrow: any;
  listaObveznika: any;
  listaZona: any;
  listaUlica: any;
  listadokumenata: any;
  qObveznik: any;
  qZona: any;
  qUlica: any;
  qDokument: any;
  brojkorisnika: number;
  dugod: number;
  dugdo: number;
  nracuna: number;
  ndana: number;
  listakorisnika: any;
  dugpoiznosu: boolean;  
  dugporacunima: boolean;
  dugpodanima: boolean;
  lista: any;
  rezultat: any;
  loading: boolean;
  loadingstart: boolean;
  brojodabranih = 0;
  oznaci: boolean;
  startdata: PokretanjePodaci = { dokumentId: 0, nadan: new Date(), danduga: new Date(), korisnici: null, rokdana: 0, tipdana: false, tipiznosa: false, tipracuna: false, tipkartica: false, oddana: new Date(), dodana: new Date(),pamtiracune:false,vrstausluge:"",konto:"" };
  starttime: number;
  elpasedtime: number;
  loginService: LoginService;
  login: User;
  vrstaUsluge: string;
  korisnik: string;
  pojedinacnikorisnik: string;
  saldo: string;
  ukupno : number;

  constructor(dataservice: httpservice, loginservice: LoginService, public dialog: MatDialog, private router: Router, @Inject(LOCALE_ID) private locale: string, private _snackBar: MatSnackBar) {
    this.getservice = dataservice;
    this.loginService = loginservice;

  }
  ngOnInit() {
    this.oznaci = true;
    this.startdata.nadan = new Date();
    this.loading = false;
    this.loadingstart = false;
    this.brojkorisnika = 0;
    this.qObveznik = "";
    this.qZona = "";
    this.qUlica = "";
    this.qDokument = "";
    this.dugod = 0;
    this.dugdo = 0;
    this.nracuna = 0;
    this.ndana = 0;
    this.korisnik = "";
    this.pojedinacnikorisnik = "0";
    this.saldo = "0";
    this.ukupno = 0;

    this.loginService.currentUser.subscribe(data => {
      this.login = data;
    });

    try {
      this.getservice.GetRequest<Message>("komunalno/obveznik/list").subscribe(result => {
        if (result.status === "success") {
          this.listaObveznika = Object.values(result.data);
          this.listaObveznika.unshift({ sifra: "", naziv: "Sve" });
        }
      }, error => console.error('Get obveznike', error));
      this.getservice.GetRequest<Message>("komunalno/zona/list").subscribe(result => {
        if (result.status === "success") {
          this.listaZona = Object.values(result.data);
          this.listaZona.unshift({ sifra: "", naziv: "Sve" });
        }
      }, error => console.error('Get zone', error));
      this.getservice.GetRequest<Message>("komunalno/ulica/list").subscribe(result => {
        if (result.status === "success") {
          this.listaUlica = Object.values(result.data);
          this.listaUlica.unshift({ sifra: "", naziv: "Sve" });
        }
      }, error => console.error('Get ulice', error));
      this.DohvatiBrojKorisnika();
    }
    catch (error) {
      console.error('Log error', error);
    }
  }

  DohvatiBrojKorisnika() {
    this.getservice.GetRequest<Message>("komunalno/korisnik/count?obveznik=" + this.qObveznik + "&zona=" + this.qZona + "&ulica=" + this.qUlica).subscribe(result => {
      if (result.status === "success") {
        this.brojkorisnika = result.data;
      }
    }, error => console.error('Get data', error));
  }

  UcitajKorisnike() {
    this.loadingstart = true;
    this.starttime = new Date().getTime();
    if (this.pojedinacnikorisnik == "0")
    {
      if (this.login.vrstaUsluge) {
        if (this.startdata.tipkartica) {
          this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/listaduznika?nadan=" + formatDate(this.startdata.danduga, 'yyyy-MM-dd', this.locale) + "&obveznik=" + this.qObveznik + "&zona=" + this.qZona + "&ulica=" + this.qUlica + "&dugod=" + this.dugod + "&dugdo=" + this.dugdo + "&nracuna=" + this.nracuna + "&ndana=" + this.ndana + "&saldo=" + this.saldo + "&vrstausluge=" + this.startdata.vrstausluge).subscribe(result => {
            this.loadingstart = false;
            if (result.status === "success") {
              this.lista = new MatTableDataSource<Korisnik>(Object.values(result.data));
              //setTimeout(() => { this.lista.sort = this.sort;  }, 2000);          
              if (this.lista.data.length > 0) {
                this.elpasedtime = (new Date().getTime() - this.starttime) / 1000;
                this.lista.data.forEach(l => l.pokrenipostupak = true);
                this.selectedrow = this.lista.data[0];
                this.rezultat = null;
                this.prebroj();
              }
            }
          });
        }
        else {
          if (this.startdata.tipkartica) {
            this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/listaduznika?nadan=" + formatDate(this.startdata.danduga, 'yyyy-MM-dd', this.locale) + "&obveznik=" + this.qObveznik + "&zona=" + this.qZona + "&ulica=" + this.qUlica + "&dugod=" + this.dugod + "&dugdo=" + this.dugdo + "&nracuna=" + this.nracuna + "&ndana=" + this.ndana + "&saldo=" + this.saldo + "&vrstausluge=" + this.startdata.vrstausluge + "&oddana=" + formatDate(this.startdata.oddana, 'yyyy-MM-dd', this.locale) + "&dodana=" + formatDate(this.startdata.dodana, 'yyyy-MM-dd', this.locale)).subscribe(result => {
              this.loadingstart = false;
              if (result.status === "success") {
                this.lista = new MatTableDataSource<Korisnik>(Object.values(result.data));
                //setTimeout(() => { this.lista.sort = this.sort;  }, 2000);          
                if (this.lista.data.length > 0) {
                  this.elpasedtime = (new Date().getTime() - this.starttime) / 1000;
                  this.lista.data.forEach(l => l.pokrenipostupak = true);
                  this.selectedrow = this.lista.data[0];
                  this.rezultat = null;
                  this.prebroj();
                }
              }
            });
          }
          else {
            this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/listaduznika?nadan=" + formatDate(this.startdata.danduga, 'yyyy-MM-dd', this.locale) + "&obveznik=" + this.qObveznik + "&zona=" + this.qZona + "&ulica=" + this.qUlica + "&dugod=" + this.dugod + "&dugdo=" + this.dugdo + "&nracuna=" + this.nracuna + "&ndana=" + this.ndana + "&saldo=" + this.saldo + "&vrstausluge=" + this.startdata.vrstausluge).subscribe(result => {
              this.loadingstart = false;
              if (result.status === "success") {
                this.lista = new MatTableDataSource<Korisnik>(Object.values(result.data));
                //setTimeout(() => { this.lista.sort = this.sort;  }, 2000);          
                if (this.lista.data.length > 0) {
                  this.elpasedtime = (new Date().getTime() - this.starttime) / 1000;
                  this.lista.data.forEach(l => l.pokrenipostupak = true);
                  this.selectedrow = this.lista.data[0];
                  this.rezultat = null;
                  this.prebroj();
                }
              }
            });
          }
        }
      }
      else {
        if (this.startdata.tipkartica) {
          this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/listaduznika?nadan=" + formatDate(this.startdata.danduga, 'yyyy-MM-dd', this.locale) + "&obveznik=" + this.qObveznik + "&zona=" + this.qZona + "&ulica=" + this.qUlica + "&dugod=" + this.dugod + "&dugdo=" + this.dugdo + "&nracuna=" + this.nracuna + "&ndana=" + this.ndana + "&saldo=" + this.saldo + "&vrstausluge=" + "&oddana=" + formatDate(this.startdata.oddana, 'yyyy-MM-dd', this.locale) + "&dodana=" + formatDate(this.startdata.dodana, 'yyyy-MM-dd', this.locale)).subscribe(result => {
            this.loadingstart = false;
            if (result.status === "success") {
              this.lista = new MatTableDataSource<Korisnik>(Object.values(result.data));
              //setTimeout(() => { this.lista.sort = this.sort;  }, 2000);          
              if (this.lista.data.length > 0) {
                this.elpasedtime = (new Date().getTime() - this.starttime) / 1000;
                this.lista.data.forEach(l => l.pokrenipostupak = true);
                this.selectedrow = this.lista.data[0];
                this.rezultat = null;
                this.prebroj();
              }
            }
          });
        }
        else {
          this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/listaduznika?nadan=" + formatDate(this.startdata.danduga, 'yyyy-MM-dd', this.locale) + "&obveznik=" + this.qObveznik + "&zona=" + this.qZona + "&ulica=" + this.qUlica + "&dugod=" + this.dugod + "&dugdo=" + this.dugdo + "&nracuna=" + this.nracuna + "&ndana=" + this.ndana + "&saldo=" + this.saldo + "&vrstausluge=").subscribe(result => {
            this.loadingstart = false;
            if (result.status === "success") {
              this.lista = new MatTableDataSource<Korisnik>(Object.values(result.data));
              //setTimeout(() => { this.lista.sort = this.sort;  }, 2000);          
              if (this.lista.data.length > 0) {
                this.elpasedtime = (new Date().getTime() - this.starttime) / 1000;
                this.lista.data.forEach(l => l.pokrenipostupak = true);
                this.selectedrow = this.lista.data[0];
                this.rezultat = null;
                this.prebroj();
              }
            }
          });
        }
      }
    }
    else {
      
      if (this.startdata.tipkartica) {
        this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/duznik?nadan=" + formatDate(this.startdata.nadan, 'yyyy-MM-dd', this.locale) + "&korisnik=" + this.korisnik + "&saldo=" + this.saldo + "&oddana=" + formatDate(this.startdata.oddana, 'yyyy-MM-dd', this.locale) + "&dodana=" + formatDate(this.startdata.dodana, 'yyyy-MM-dd', this.locale)).subscribe(result => {
          this.loadingstart = false;
          if (result.status === "success") {
            this.lista = new MatTableDataSource<Korisnik>(Object.values(result.data));
            //setTimeout(() => { this.lista.sort = this.sort;  }, 2000);          
            if (this.lista.data.length > 0) {
              this.elpasedtime = (new Date().getTime() - this.starttime) / 1000;
              this.lista.data.forEach(l => l.pokrenipostupak = true);
              this.selectedrow = this.lista.data[0];
              this.rezultat = null;
              this.prebroj();
            }
          }
        });
      }
      else {
        this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/duznik?nadan=" + formatDate(this.startdata.nadan, 'yyyy-MM-dd', this.locale) + "&korisnik=" + this.korisnik + "&saldo=" + this.saldo).subscribe(result => {
          this.loadingstart = false;
          if (result.status === "success") {
            this.lista = new MatTableDataSource<Korisnik>(Object.values(result.data));
            //setTimeout(() => { this.lista.sort = this.sort;  }, 2000);          
            if (this.lista.data.length > 0) {
              this.elpasedtime = (new Date().getTime() - this.starttime) / 1000;
              this.lista.data.forEach(l => l.pokrenipostupak = true);
              this.selectedrow = this.lista.data[0];
              this.rezultat = null;
              this.prebroj();
            }
          }
        });
      }
    }    
    this.UcitajDokumente();
  }

  UcitajDokumente() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/dokumentvrsta/List").subscribe(result => {
      if (result.status === "success") {
        this.listadokumenata = Object.values(result.data);        
      }
    }, error => console.error('Get obveznike', error));
  }


  dugpoiznosuchange(newValue) {
    if (newValue) {
      this.startdata.tipiznosa = true;
    }
    else {
      this.startdata.tipiznosa = false;
    }
  }

  iznosracunachange(newValue) {
    if (!newValue) {
      this.dugod = 0;
      this.dugdo = 0;
    }
  }

  dugporacunimachange(newValue) {
    if (newValue) {
      this.startdata.tipracuna = true;
    }
    else {
      this.startdata.tipracuna = false;
    }
  }

  nracunachange(newValue) {
    if (!newValue) {
      this.nracuna = 0;
    }    
  }


  dugpodanimachange(newValue) {
    if (newValue) {
      this.startdata.tipdana = true;
    }
    else {
      this.startdata.tipdana = false;
    }
  }
  ndanachange(newValue) {
    if (!newValue) {
      this.ndana = 0;      
    }
  }
 
  dugkarticachange(newValue) {
    if (newValue) {
      this.startdata.tipkartica = true;
    }
    else {
      this.startdata.tipkartica = false;
    }
  }

  highlight(row) {
    this.selectedrow = row;
    if (row.prikazikarticu) {
      row.prikazikarticu = false;
    }
    else {
      row.prikazikarticu = true;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lista.filter = filterValue.trim().toLowerCase();
  }

  openDialog() {
    let broj: number = 0;
    this.lista.data.forEach((e, i) => {
      if (e.pokrenipostupak) {
        ++broj;
      }
    });
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          pitanje: 'Da li ste sigurni ?',
          pojasnjenje: 'Pokretanje '+broj+' postupka'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.PokreniPostupak();
        }
      });
    
  }


  PokreniPostupak() {
    if (this.startdata.dokumentId != 0) {
      let data: Korisnik[] = [];
      this.loading = true;
      this.lista.data.forEach((e, i) => {
        if (e.pokrenipostupak) {
          data.push(e);
        }
      }
      );
      this.startdata.korisnici = data;

      this.getservice.PostRequest<Message>("komunalno/saldakonti/utuzivanje/start", this.startdata).subscribe(result => {
        this.PostupakZavrsen(result.data, "Uredu");
        this.lista = null;
        this.loading = false;
      }, error => {
        this.rezultat = "Greška prilikom pokretanja postupka " + error;
        console.error('Pokreni postupak', error);
      });
    }
  }

  prebroj() {
    this.brojodabranih = 0;
    this.ukupno = 0;
    this.lista.data.forEach((e, i) => {
      
      if (e.pokrenipostupak) {
        this.brojodabranih++;
        this.ukupno += e.iznos;
      }
      
    }
    );
  }

  pocetniuvjeti() {
    this.lista = null;
  }

  PostupakZavrsen(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  OznaciSve() {
    if (this.oznaci) {
      this.lista.data.forEach((e, i) => {
        e.pokrenipostupak = false;
      });
      this.oznaci = false;
    }
    else {
      this.lista.data.forEach((e, i) => {
        e.pokrenipostupak = true;
      });
      this.oznaci = true;
    }
    this.prebroj();
  }

  OdabirKorisnika(izbor: string) {
    this.korisnik = izbor;
  }
 
  onchangeKorisnik(value) {
    if (this.pojedinacnikorisnik === "0") {
      this.korisnik = "";
    }
  }
}



<div class="addressbar">
  <span class="addresstext">Komunalno / Obrada dugovanja / Postupci</span>
</div>
<div class="containerpadding">
  <mat-card appearance="outlined">
    <mat-card-title>
      Pokrenuti postupci (dokumenti)
    </mat-card-title>

    <p *ngIf="ucitavanje"><em>Učitavam...</em></p>

    <hr />
    <obveznik-zona-ulica (podaci)="OdabirUvjeta($event)"></obveznik-zona-ulica>
    <div class="col-12 mt-1">
      <mat-card appearance="outlined">
        <mat-form-field class="col-lg-3 col-12">
          <mat-label>Prikaži</mat-label>
          <mat-select [(ngModel)]="aktivan" name="aktivan">
            <mat-option value="1">Sve</mat-option>
            <mat-option value="2">Aktivne</mat-option>
            <mat-option value="3">Neaktivne</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="listadokumenata" appearance="fill" class="col-lg-3 col-12">
          <mat-label>Dokument (status)</mat-label>
          <mat-select ngDefaultControl [(ngModel)]="vrstadokumentaid" name="dokument">
            <mat-option value="0">Sve</mat-option>
            <mat-option *ngFor="let dokument of listadokumenata" [value]="dokument.pravniDokumentVrstaId">
              {{dokument.sifra}} {{dokument.naziv}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="login.vrstaUsluge" appearance="fill" class="col-lg-3 col-12">
          <mat-label>Vrsta usluge</mat-label>
          <mat-select ngDefaultControl name="vrstaUsluge" [(ngModel)]="vrstaUsluge">
            <mat-option value="">
              Sve
            </mat-option>
            <mat-option value="V">
              Voda
            </mat-option>
            <mat-option value="C">
              Čistoća
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p>
          <button mat-button (click)="Ucitaj()" class="align-items-center ok-color col-3"><mat-icon aria-hidden="false" aria-label="Osvježi">refresh</mat-icon> Osvježi</button>
        </p>
      </mat-card>
    </div>

    <br />
    <mat-card-actions>
    <button (click)="openDialog()" mat-stroked-button class="delete-color"><mat-icon aria-hidden="false" aria-label="Brisanje">delete</mat-icon> Obustavi postupak</button>&nbsp;
    <button (click)="Ispis()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Brisanje">print</mat-icon> Ispis</button>&nbsp;
    <button (click)="IspisSaKarticom()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Brisanje">print</mat-icon> Ispis sa ana. karticom</button>&nbsp;
    <button *ngIf="firmapodaci.drzava==1" (click)="KreirajPrijedlog()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Pošalji">mail</mat-icon> Pošalji prijedlog na eOvrhe</button>
    <button *ngIf="firmapodaci.drzava==2" (click)="KreirajDatotekuIzvrsenika()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Kreiraj datoteku">mail</mat-icon> Kreiraj datoteku izvršenika</button>&nbsp;
    <button *ngIf="firmapodaci.drzava==2" (click)="KreirajPrijedlogSokop()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Kreiraj datoteku">mail</mat-icon> Kreiraj datoteku prijedloga</button><br />
      </mat-card-actions>
    <mat-form-field>
      <mat-label>Traži</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Tekst">
    </mat-form-field>
    <div class="mat-elevation-z6">
      <table *ngIf="lista" mat-table [dataSource]="lista" multiTemplateDataRows matSort style="width:100%">

        <ng-container matColumnDef="ime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ime/Naziv </th>
          <td mat-cell *matCellDef="let element"> {{element.ime}} </td>
        </ng-container>

        <ng-container matColumnDef="sifra">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Korisnik </th>
          <td mat-cell *matCellDef="let element"><span (click)="kartica(element)" class="material-icons pokazivac align-bottom">expand_more</span>  {{element.sifra}} </td>
        </ng-container>

        <ng-container matColumnDef="vrstaDokumenta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Vrsta dokumenta </th>
          <td mat-cell *matCellDef="let element"> {{element.vrstaDokumenta}} </td>
        </ng-container>
        <ng-container matColumnDef="broj">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Broj </th>
          <td mat-cell *matCellDef="let element"> {{element.pravniDokumentId}} </td>
        </ng-container>
        <ng-container matColumnDef="datum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum pokretanja </th>
          <td mat-cell *matCellDef="let element"> {{element.datum | date: 'dd.MM.yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="datumDuga">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum duga </th>
          <td mat-cell *matCellDef="let element"> {{element.datumDuga | date: 'dd.MM.yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="rokDana">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Rok za plaćanje (dana) </th>
          <td mat-cell *matCellDef="let element"> {{element.rokDana}} </td>
        </ng-container>
        <ng-container matColumnDef="tipIznosa">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dug po iznosu </th>
          <td mat-cell *matCellDef="let element"><mat-checkbox [(ngModel)]="element.tipIznosa" name="aktivan" [disabled]="true"></mat-checkbox></td>
        </ng-container>
        <ng-container matColumnDef="tipKartica">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dio kartice </th>
          <td mat-cell *matCellDef="let element"><mat-checkbox [(ngModel)]="element.tipKartica" name="aktivan" [disabled]="true"></mat-checkbox></td>
        </ng-container>
        <ng-container matColumnDef="iznos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Iznos duga </th>
          <td mat-cell *matCellDef="let element" class="text-right"> {{element.iznos | number: '1.2-2':'hr'}} </td>
        </ng-container>
        <ng-container matColumnDef="aktivan">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Aktivan </th>
          <td mat-cell *matCellDef="let element" class="text-center"><mat-checkbox [(ngModel)]="element.aktivan" name="aktivan" [disabled]="true"></mat-checkbox></td>
        </ng-container>
        <ng-container matColumnDef="vrstausluge">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Vrsta usluge </th>
          <td mat-cell *matCellDef="let element"> {{element.vrstaUsluge}} </td>
        </ng-container>
        <ng-container matColumnDef="konto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Konto </th>
          <td mat-cell *matCellDef="let element"> {{element.konto}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <mat-tab-group mat-align-tabs="start" *ngIf="element.prikazikarticu">
              <mat-tab label="Računi dokumenta">
                <dokument-racuni *ngIf="element.sifra" [dokumentid]="element.pravniDokumentId" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"></dokument-racuni>
              </mat-tab>
              <mat-tab label="Analitička kartica">
                <kartica *ngIf="element.sifra" [korisnik]="element.sifra"></kartica>
              </mat-tab>
              <mat-tab label="Analitička kartica - kombinirana">
                <kartica-kombo *ngIf="element.sifra" [korisnik]="element.sifra"></kartica-kombo>
              </mat-tab>
              <mat-tab label="Računi" *ngIf="!login.vrstaUsluge">
                <otvoreni-racuni *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="''"></otvoreni-racuni>
                <zatvoreni-racuni *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="''"></zatvoreni-racuni>
              </mat-tab>
              <mat-tab label="Računi voda" *ngIf="login.vrstaUsluge">
                <otvoreni-racuni *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="'V'"></otvoreni-racuni>
                <zatvoreni-racuni *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="'V'"></zatvoreni-racuni>
              </mat-tab>
              <mat-tab label="Računi čistoća" *ngIf="login.vrstaUsluge">
                <otvoreni-racuni *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="'C'"></otvoreni-racuni>
                <zatvoreni-racuni *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="'C'"></zatvoreni-racuni>
              </mat-tab>
              <mat-tab label="Računi automatika" *ngIf="!login.vrstaUsluge">
                <otvoreni-racuni-id *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="''"></otvoreni-racuni-id>
                <zatvoreni-racuni-id *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="''"></zatvoreni-racuni-id>
              </mat-tab>
              <mat-tab label="Računi voda automatika" *ngIf="login.vrstaUsluge">
                <otvoreni-racuni-id *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="'V'"></otvoreni-racuni-id>
                <zatvoreni-racuni-id *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="'V'"></zatvoreni-racuni-id>
              </mat-tab>
              <mat-tab label="Računi čistoća automatika" *ngIf="login.vrstaUsluge">
                <otvoreni-racuni-id *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="'C'"></otvoreni-racuni-id>
                <zatvoreni-racuni-id *ngIf="element.sifra" [korisnik]="element.sifra" [vrstausluge]="'C'"></zatvoreni-racuni-id>
              </mat-tab>
            </mat-tab-group>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns;" (click)="highlight(row)" [ngClass]="{highlight: selectedrow.pravniDokumentId === row.pravniDokumentId}" class="example-element-row"></tr>
        <tr mat-row *matRowDef="let row;columns: ['expandedDetail']" class="example-detail-row"></tr>

      </table>

      <mat-paginator [pageSizeOptions]="[ 10,20,50]" showFirstLastButtons></mat-paginator>
      </div>
  </mat-card>
      <hr />
    </div>
